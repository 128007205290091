<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#313189" />
      <div v-else>
      <!-- header section -->
      <section class="header">
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-6">
              <div v-if="header_title.length > 0" v-html="$prismic.asHtml(this.header_title)" class="header__title"></div>
              <div v-if="header_text.length > 0" v-html="$prismic.asHtml(this.header_text)" class="header__text"></div>
            </div>
            <div class="col-12 col-lg-6">
              <div v-if="header_button_label.length > 0" class="header__box">
                <button @click.prevent="scrollToLocations" type="button" class="btn main-button">{{ $prismic.richTextAsPlain(this.header_button_label) }} <img src="@/assets/images/shared/yellow_arrow.svg" alt="arrow" /></button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <span id="locations"></span>
      <!-- locations section -->
      <section class="locations">
        <div class="container">
          <h2 data-aos="fade-up" v-if="locations_title.length > 0" class="main-title">{{ $prismic.richTextAsPlain(this.locations_title) }}</h2>
          <div v-for="(item, index) in this.locations_gallery" :key="`${index}`" class="locations__box">
            <div v-if="index % 2 === 0" data-aos="fade-up" data-aos-delay="150" data-aos-offset="100" class="row">
              <div class="col-12 col-lg-5">
                <img class="img-fluid locations__image" :src="item.location_image.url" :alt="item.location_image.alt" />
              </div>
              <div class="col-12 col-lg-7">
                <div v-html="$prismic.asHtml(item.location_name)" class="locations__title"></div>
                <hr class="locations__line locations__line--right" />
                <div v-html="$prismic.asHtml(item.location_text)" class="locations__text"></div>
              </div>
            </div>
            <div v-else data-aos="fade-up" data-aos-delay="150" data-aos-offset="100" class="row">
              <div class="col-12 col-lg-5 order-lg-2">
                <img class="img-fluid locations__image" :src="item.location_image.url" :alt="item.location_image.alt" />
              </div>
              <div class="col-12 col-lg-7 order-lg-1">
                <div v-html="$prismic.asHtml(item.location_name)" class="locations__title locations__title--left"></div>
                <hr class="locations__line locations__line--left" />
                <div v-html="$prismic.asHtml(item.location_text)" class="locations__text locations__text--left"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
export default {
  name: 'LocationsPage',
  components: {
    Spinner
  },
  data: () => {
    return {
      cmsContentPending: false,
      header_title: null,
      header_text: null,
      header_button_label: null,
      header_button_url: null,
      locations_title: null,
      locations_gallery: null
    }
  },
  methods: {
    async getLocationsContent () {
      await this.$prismic.client.getSingle('locationspage', { lang: this.$i18n.lang })
        .then((document) => {
          // header section
          this.header_title = document.data.header_title
          this.header_text = document.data.header_text
          this.header_button_label = document.data.header_button_label
          this.header_button_url = document.data.header_button_url
          // locations section
          this.locations_title = document.data.locations_title
          this.locations_gallery = document.data.locations_gallery
          this.cmsContentPending = true
        })
    },
    scrollToLocations () {
      const locationsSection = document.getElementById('locations')
      locationsSection.scrollIntoView({ behavior: 'smooth' })
    }
  },
  created () {
    this.getLocationsContent()
  },
  watch: {
    '$i18n.locale': function (newValue) {
      this.getLocationsContent()
    }
  }
}
</script>

<style lang="scss">
  @import "./LocationsPage.scss";
</style>
